import { Option } from "@/@types/Option";

export function multipleSelectionLabel(value: number[] | Option[] | null): string | number | null {
    return value && value.length
        ? value.length > 1
            ? `${value.length} valittu`
            : typeof value[0] === "object"
            ? value[0].name
            : value[0]
        : null;
}
